import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './http-interceptors';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { UploadComponent } from './modules/standalone/upload/upload.component';
import { DownloadItemsComponent } from './modules/standalone/download-items/download-items.component';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './custom-reuse.route.stragtegy';
import { AdDirective } from './directives/ad.directive';
import { InitialsImageDirective } from './directives/initials-image.directive';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    UploadComponent,
    DownloadItemsComponent,
    AdDirective,
    InitialsImageDirective
  ],
  providers: [
    httpInterceptorProviders,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000, horizontalPosition: 'right', verticalPosition: 'top' } },
    // {
    //   provide:RouteReuseStrategy, useClass:RouteReuseService
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
