import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[dcrsInitialsImage]',
  standalone: true,

})
export class InitialsImageDirective {

  @Input() email: string = '';
  @Input() size: number = 100;

  constructor(private el: ElementRef<HTMLImageElement>) {}

  ngOnChanges(): void {
    this.generateInitialsImage();
  }

  private generateInitialsImage(): void {
    if (!this.email) {
      return;
    }

    const nameParts = this.email.split('@')[0].split('.');
    const initials = nameParts.map(part => part[0].toUpperCase()).join('').slice(0, 2);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.width = this.size;
    canvas.height = this.size;

    context.fillStyle = '#0078D4';
    context.fillRect(0, 0, this.size, this.size);

    context.fillStyle = '#FFFFFF';
    context.font = `bold ${this.size / 2}px Arial`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.fillText(initials, this.size / 2, this.size / 2);

    this.el.nativeElement.src = canvas.toDataURL();
  }


}
