import { AfterViewInit, Component, ViewContainerRef } from '@angular/core';
import { CONSTANT, getQueryParameterByName } from './constants/constant';
import { environment } from 'src/environments/environment';
import { DynamicLoaderService } from './dynamic-loader/dynamic-loader.service';
import { DownloadNotifierService } from './services/download-notifier.service';
import { ScreenshotBlockerService } from './services/screenshot-blocker.service';

@Component({
  selector: 'dcrs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  setDefaultTheme() {
    const theme = CONSTANT.getTheme();
    if (theme?.colors) {
      this.applyTheme(theme?.colors);
    }
  }

  applyTheme(theme: any) {
    if (theme) {
      Object.keys(theme).forEach(key => {
        document.documentElement.style.setProperty(`--dc-${key}-c`, theme[key]);
      });
    }
  }

  vcr!: ViewContainerRef;

  constructor(
    private dynamicLoaderService: DynamicLoaderService,
    private downloadNotifierService: DownloadNotifierService,
    private screenshotBlockerService: ScreenshotBlockerService) {
    this.setDefaultTheme();
    CONSTANT.setPreviewThemeSession(getQueryParameterByName('preview') || '');
    const themePreview = CONSTANT.getPreviewThemeSession();
    if (themePreview === 'true') {
      let themeIndex: any = getQueryParameterByName('themeIndex');
      try { themeIndex = parseInt(themeIndex) } catch (e) { themeIndex = 0 }
      const themes = CONSTANT.getPreviewTheme() || [];
      if (themes?.length) {
        const theme = (themes[themeIndex] || themes[0]).data;
        this.applyTheme(theme);
      }
    }
    console.log('environment loaded - ', environment.name);
  }

  ngOnInit() {
    //this.screenshotBlockerService.initializeBlocker();
  }

  ngAfterViewInit(): void {
    this.downloadNotifierService.uploadItems.subscribe(item => {
      this.dynamicLoaderService.loadComponent({ componentName: 'UploadComponent', standalone: true, skipClear: true }, this.vcr, {
        inputs: {
          uploadFileFolders: item
        }
      }).then();
    });
    this.downloadNotifierService.onDropItems.subscribe(item => {
      this.dynamicLoaderService.loadComponent({ componentName: 'UploadComponent', standalone: true, skipClear: true }, this.vcr, {
        inputs: {
          dropedData: item
        }
      }).then();
    });

    this.downloadNotifierService.uploadItemsPb.subscribe(item => {
      this.dynamicLoaderService.loadComponent({ componentName: 'UploadPbComponent', standalone: true, skipClear: true }, this.vcr, {
        inputs: {
          uploadFileFolders: item
        }
      }).then();
    });
    this.downloadNotifierService.onDropItemsPb.subscribe(item => {
      this.dynamicLoaderService.loadComponent({ componentName: 'UploadPbComponent', standalone: true, skipClear: true }, this.vcr, {
        inputs: {
          dropedData: item
        }
      }).then();
    });

    this.downloadNotifierService.importOtherFiles.subscribe(item => {
      this.dynamicLoaderService.loadComponent({ componentName: 'UploadComponent', standalone: true, skipClear: true }, this.vcr, {
        inputs: {
          otherFile: item
        }
      }).then();
    });
  }
}
