import { UserPermissionsModel } from "../models/user-permission.model";
import { CONSTANT, GroupType, GroupTypeName, UserTypeName } from "./constant"





export class RoleWisePermissions {

   static configPermissions() {
        let permission: UserPermissionsModel;
        const userProfile = CONSTANT.getUserProfile();
        if (userProfile.user.groupType.toLowerCase() == GroupTypeName.PARTNER.toLowerCase() && userProfile.profile.type.toLowerCase() == UserTypeName.ADMIN.toLowerCase()) {
            permission = PARTNER;
        } else if (userProfile.user.groupType.toLowerCase() == GroupTypeName.ADMIN.toLowerCase()) {
            permission = ADMIN;
        } else   {
            permission = USER;
        }
    CONSTANT.setPermissions(permission);
    }


}


const PARTNER: UserPermissionsModel = {
    exportlogsMenu: true,
    rebuildIndexMenu: true,
    drmSettingsMenu: true,
    archiveMenu: true,
    filePermissionMenu: true,
    showUserGroups: true,
    createGroup: true,
    groupMoreActions:true,
    rejectUser:true,
    approveUser:true,
    
    adminRoutes: true,
    settingsPage: true,
    devicesPage: true,
    themePage: true,
    groupsPage: true,
    accessPermissionsPage: true,
    ipManagePage: true,


    // drive through API 
    viewMetaData: true,
    timeFoxModule: true,
}

const ADMIN: UserPermissionsModel = {
    exportlogsMenu: false,
    rebuildIndexMenu: false,
    drmSettingsMenu: true,
    archiveMenu: true,
    filePermissionMenu: true,
    showUserGroups: true,
    createGroup: false,
    groupMoreActions:false,
    rejectUser:true,
    approveUser:false,

    adminRoutes: true,
    settingsPage: false,
    devicesPage: true,
    themePage: false,
    groupsPage: true,
    accessPermissionsPage: true,
    ipManagePage: true,


    // drive through API 
    viewMetaData: true,
    timeFoxModule: true,
}

const USER: UserPermissionsModel = {
    exportlogsMenu: false,
    rebuildIndexMenu: false,
    drmSettingsMenu: false,
    archiveMenu: false,
    filePermissionMenu: false,
    showUserGroups: false,
    createGroup: false,
    groupMoreActions:false,
    rejectUser:false,
    approveUser:false,
   
    adminRoutes: false,
    settingsPage: false,
    devicesPage: false,
    themePage: false,
    groupsPage: false,
    accessPermissionsPage: false,
    ipManagePage: false,


    // drive through API 
    viewMetaData: true,
    timeFoxModule: true,
}