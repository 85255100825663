export const dataroom_messages = {
    "notsupportedcharacters":"An unsupported special character found. Folder and file name can not have \\ / : * ? < > | #.",
    "sampledynamicmsg":(corporateId: string | number) => `this mesage ${corporateId}`,
    "admin_enterPasswordExists":"New Password can not be same as old password",
    "admin_passwordupdated":"Password updated successfully",
    "error_changePassword":"Reset password URL is invalid or expired.Please click on the latest link from your mail to Change Password.",
    "error_changePassword_new":"Reset password URL is invalid or expired. Please contact admin for new link.",
    "error_changePassword_account_activated":"Account already activated",
    "error_nopermission":"Sorry, you are do not have permission to perform this action. Please contact your full admin user.",
    "somthing_went_wrong":"Somthing went wrong",
    "settings_updated":"Settings Updated!",
    "required_firstname":"Please enter first name",
    "required_lastname":"Please enter last name",
    "invalid_emailId" : "Invalid email address. Please provide a valid email.",
    "invalid_phone" : "Invalid phone number. Please provide a valid phone number.",
    "metadata_saved":"Metadata saved!",
    "metadata_deleted":"Metadata Deleted!",
    "session_expired":"Session Expired!",
    "error_in_change_password":"Error in Change Password",
    "failed_to_copy":"Failed to copy",
    "tag_name_already_exists":"Tag name already exists",
    "device_blocked":"Device Blocked!",
    "device_deleted":"The device Deleted successfully.",
    "start_date_before_end_date":"End date and time should be greater than or equal to start date and time",
    "theme_saved_preview":"Theme saved in preview history",
    "theme_removed":"Theme Removed successfully!",
    "folder_created":"Folder Created!",
    "rebuild_success":"Rebuild success!",
    "file_saved":"File saved successfully!",
    "folder_renamed":"Folder renamed successfully",
    "folder_link_copied":"Folder link copied",
    "file_link_copied":"File link copied",
    "file_renamed":"File renamed successfully",
    "file_checkout":"File checkedout successfully",
    "file_checkin":"File checkedin successfully",
    "folder_deleted":"Folder deleted successfully",
    "folder_parmanently_deleted":"Folder parmanently deleted successfully",
    "folder_restored":"Folder restored successfully",
    "files_deleted":"File(s) deleted successfully",
    "files_parmanently_deleted":"File(s) parmanently deleted successfully",
    "files_restored":"File(s) restored successfully",
    "user_details_saved":"User details saved successfully!",
    "user_details_updated":"User details updated successfully!",
    "user_saved":"User saved!",
    "resend_activation_mail":"Successfully resend the activation mail",
    "user_blocked":"User blocked successfully",
    "user_unblocked":"User unblocked successfully",
    "user_deleted":"User deleted successfully",
    "user_group_reassigned":"User Group reassigned successfully",
    "user_group_changed":"User Group changed successfully",
    "user_group_unassigned":"User group unassigned.",
    "fill_all_mandatory_fields":"Please fill all the mandatory fields",
    "enter_phone_number":"Please enter phone number as 2 factor authentication is selected",
    "can_not_allow_as_group_blocked":"Can not allow to perform the action as group is blocked.",
    "enter_valid_mobile_number":"Please provide valid mobile number",
    "group_deleted":"Group deleted successfully",
    "user_group_moved":"User Group moved successfully",
    "group_created":"Group created successfully",
    "group_updated":"Group updated successfully",
    "rejected_successfully":"rejected successfully",
    "approved_successfully":"approved successfully",
    "saved_successfully":"Saved Successfully",
    "something_went_wrong":"Something went wrong. Please try again!",
    "download_started":"The download started. You will receive an email with a link after download is completed.",
    "allowing_print_processed":"Request for allowing print processed",
    "block_print_processed":"Request for block print processed",
    "block_download_processed":"Request for block download processed",
    "allowing_download_processed":"Request for allowing the download processed",
    "dataroom_security_updated":"Data Room security updated successfully",
    "tag_added":"Tag added",
    "tag_deleted":"Tag deleted",
    "enter_tag_more_given_characters":"Please enter tag more than or equal to 3 characters",
    "cannot_move_to_same_folder":"Cannot move to the same folder",
    "no_results_found":"No results found",
    "notpaidfeature":"Sorry, you do not have permission to perform this action as this is paid feature. Please contact us at support@dcirrus.com.",
    "noallowedformobile": "Not allowed in mobile device. Allowed for desktop and tablets only.",
    "forgotpassdone": "A link has been sent to your email id <EMAILID> to reset your password. Redirecting to login page.",
    "forgotpasserror1": "Login Id does not exists. Please use the proper Login Id.",
    "forgotpasserror2": "Login Id / Corporate Id could not be matched. Please use the proper Login Id and Corporate Id",
    "enterloginid": "Please enter valid email id",
    "enterpassword": "Please enter password",
    "entercorporateid": "Please enter corporate id",
    "enternewpassword": "Please enter valid new password",
    "enternewconfirmpass": "Please enter confirm password same as new password",
    "passchanged": "Password changed successfully.You will be redirected to login page",
    "login_fillin": "Please fill in required values",
    "passchangeproblem": "Password could not be changed. Please try again",
    "deviceblockedexceedloginBussiness": "You have exceeded the maximum no of attempts to login. Your account has been blocked. Please do forgot password to access your account again.",
    "deviceblockedexceedloginIndividual": "You have exceeded the maximum no of attempts to login. Your account has been blocked. Please do forgot password to access your account again",
    "deviceblocked": "Your account has been blocked, please do forgot password to unblock your account",
    "invalidcredentials": "Please enter valid credentials",
    "invalidip": "Your IP Address is not whitelisted. Please contact your administrator.",
    "userapprovalrequest": "Your request to access this account has been forwarded to your project admin. Please contact your project administrator",
    "userblocked": "This account has been blocked. Please do forgot password to access your account again.",
    "userblockedbyadmin": "Your account has been blocked. Please contact your project administrator",
    "userblocked3timesattemptcorporate": "You have exceeded the maximum no of attempts to login to corporate. Your account has been blocked. An activation mail has been sent to your email id. Please activate your account from activation link or please contact your project administrator.",
    "userblockedafterthreewrongpassword": "Your account has been blocked. Please do forgot password to access your account again.",
    "captchamismatch": "Captcha mismatch",
    "entercaptcha": "Please enter captcha",
    "newpasssameasoldpass": "New Password is same as current password. Please enter a valid new password",
    "corporateExpired": "Your account is expired. Please contact DCirrus support team",
    "corporateExpiredUser": "Access to the project is expired. To get access, please contact your administrator",
    "enterotp": "Please enter OTP",
    "OTPmismatch": "OTP expired or OTP entered is wrong. Please try again.",
    "deviceblockedbyuser": "Your device is no longer accessible. You can do forgot password to unblock your device.",
    "genericmessage": "Unexpected error occur. Please contact your system administrator.",
    "corpsetupincomplete": "Data room setup not complete yet. Please contact your system administrator.",
    "corpsetupforcecomplete": "Storage provider not defined. Please select your storage provider.",
    "usernotactivate": "Account is not activated. Activation mail has been sent to your email. Please activate your account from activation link.",
    "usernotactivateforgotpassword": "Account is not activated. Please activate your account.",
    "domainMismatchMsg": "If you want to login to  <&REGION;> region dataroom, ",
    "changepasswordmsg": "Please change your temporary password. You will be redirected to change password screen.",
    "ipaddressblocked": "Your device is blocked. Please contact your administrator.",
    "corporateInactive": "Project no longer exists.",
    "oldpasswordwrong": "You have entered incorrect old password. Please enter valid old password",
    "sameoldnewpassword": "New Password is same as current password. Please enter a valid new password",
    "docidsdeletedsuccess": "Deleted successfully",
    "docidsdeletedfail": "Could not be Deleted. Please try again",
    "suretodeletedoc": "Are you sure to delete the document?",
    "suretodeletefolder": "Are you sure to delete this folder?",
    "couldnotopenviewer": "Could not open viewer. Please try again",
    "enterfoldername": "Please enter the folder name",
    "enterfilename": "Please enter a file name",
    "couldnotprocessrequest": "Could not process the request. Please try again..",
    "couldnotcompleteinsight": "Could not process the request. The file can not be opened for document insight.",
    "filesuploadedprocess": "Files uploaded. A status report file will be downloaded. Processing the update..",
    "filesuploadedsuccessfully": "Files uploaded successfully in",
    "filesuploadedsuccessfully1": "Files uploaded successfully. Records will get updated in few seconds",
    "filesuploadedstatuscheck": "Updating file status...",
    "lockedfilesuploadfailed": "Files could not be uploaded because all documents are locked",
    "lockedfilesuploadedno": "Files uploaded successfully except <LOCKEDFILES>. Records will get updated in few seconds",
    "admspreparingforshare": "Sharing the document list...",
    "documentlistshared": "Document list shared successfully",
    "documentlistcollaborated": "Collaboration link shared successfully",
    "admcopystarted": "Preparing files to copy...",
    "admcopystartedsingle": "Preparing file to copy...",
    "admcopydone": "Files copied successfully",
    "admmovestarted": "Preparing files to move...",
    "admmovestartedsingle": "Preparing file to move...",
    "admmovedone": "Files moved successfully",
    "admselectonefolder": "Please select at least one folder",
    "admindexeddone": "Documents indexed successfully",
    "admselectcase": "Please select case",
    "admselectbucket": "Please select a folder",
    "admselectviewingrights": "Please select viewing rights",
    "admselectsensitivity": "Please select sensitivity",
    "admentersource": "Please enter the source",
    "admenterdesc": "Please enter a description",
    "admenterauthor": "Please enter author",
    "admenterfileddate": "Please enter filed date.",
    "admenterfilingdate": "Please enter filing date",
    "admentermeaningdate": "Please enter meaningful date",
    "admrequiredfield": "Please enter the required field",
    "suretooverwritedoc": "Some documents already exist. Do you want to override these documents?",
    "disablepopup": "Please disable your popup blocker",
    "inboundsharecreated": "File deposit request sent successfully",
    "star_not_allowed_tags": "Character space, plus, star is now allowed in tags",
    "admenterEmail": "Please enter email id",
    "admselectcheckbox": "Please select at least one checkbox",
    "admsharedfoldersecurityadded": "Data Room security updated successfully",
    "admsharedfoldersecuritynotadded": "Data Room security could not be updated",
    "couldnotopenFile": "This file type is not supported to be viewed in the system. Please download the file if allowed and view it locally.",
    "couldnotopenFileZip": "This file type is not supported to be viewed in the system.",
    "viewerrequestinprocess": "Another document is in process. Please try later",
    "viewermorethan300MB": "Sorry can not display more than 300 MB files. Please download the file to view",
    "viewerchoosetype": "Please choose viewer",
    "requestsent": "Request resent successfully",
    "requestnotsent": "The request could not be sent successfully",
    "allowdownload": "Request for allowing the download processed",
    "blockdownload": "Request for block download processed",
    "allowprint": "Request for allowing print processed",
    "blockprint": "Request for block print processed",
    "allowupload": "Request for allowing upload processed",
    "blockupload": "Request for block upload processed",
    "fenceview": "Request for activating fence view processed",
    "blockfenceview": "Request for removing fence view processed",
    "allowsign": "A request to allow signing processed",
    "blocksign": "Request to block signing processed",
    "zipextracted": "Zip extracted successfully",
    "zipselectextract": "Please select zip folder or file to extract",
    "zipselectextracttarget": "Please select to which folder, zip needs to be extracted",
    "admnorecordsfound": "No Records Found",
    "admvalidemail": "Please enter a valid email",
    "admvalidmobile": "Please enter valid mobile",
    "admdownloadstarted": "The download started. You will be notified after the download is complete",
    "cannotrenamemultiple": "Sorry, can not rename multiple files. Please try again",
    "maxtags": "Cannot add more than 10 tags",
    "tagadded": "Tag added",
    "tagdeleted": "Tag deleted",
    "noversionfound": "No versions found",
    "versionadded": "New Version added",
    "versiondeleted": "Version deleted",
    "restoreddocumentssuccess": "Selected documents restored successfully",
    "restoreddocumentsfail": "Selected documents could not be restored. Please try again",
    "permdeldocumentssuccess": "Selected documents deleted permanently successfully",
    "permdeldocumentsfail": "Selected documents could not be deleted permanently. Please try again",
    "sharereceipientnotfound": "Please add some recipients for the share",
    "copyinprogress": "Document Copy is already in progress. You will be informed after process done",
    "moveinprogress": "Document Move is already in progress. You will be informed after process done",
    "permissiondenied": "Sorry, you do not have right to perform the action. Please contact your administrator",
    "lockeddoc": "Locked document. Please contact your project administrator",
    "drmappliedshareerror": "This action cannot be performed on DRM applied files. Please contact your administrator",
    "folderexists": "Folder already exists. Please choose a different name",
    "fileexists": "File already exists. Please choose a different name",
    "sourcetargetfoldersamecopy": "The source folder and target folder cannot be the same.",
    "sourcetargetfolderpathsamecopy": "The source folder path and target folder path cannot be the same.",
    "admdownloadstartedzip": "The download started. You will receive a zip file",
    "admdownloadstartedSingleFile": "The download started. Your file(s) will be downloaded shortly",
    "admdocumentlocked": "The document is locked. Please contact your administrator",
    "admuploaddisclaimer": "If you select more than 200 files, browser will take more time to process the file upload request. For best performance puts the file in a DCirrus sync folder on your laptop or desktop",
    "admfolderexists": "Folder exists",
    "admfoldernameformat": "An unsupported special character found in <FOLDERFILE> : <NAME>. Please change the <FOLDERFILE> and try again.",
    "admdocumentintrash": "Sorry, cannot perform the request. The file is  the trash",
    "admfolderintrash": "Sorry, cannot perform the request. The folder is  the trash",
    "admentersubject": "Please enter the subject",
    "admselectonedocument": "Please select at least one document",
    "admssearchfilter": "No search filter given",
    "admtagnameformat": "Tag name can not have \\\\ / : * ? < > | #",
    "admtagnameexists": "Tag name already exists",
    "admtagnamelength": "Please enter tag more than or equal to 3 characters",
    "admemailalreadyadded": "Email already added",
    "renamefolderdone": "Folder renamed successfully",
    "filerenamedone": "File renamed successfully",
    "createfolderdone": "Folder created successfully",
    "allocatedspaceover": "Sorry, you have used all of your allocated storage <&ALLOCATEDSPACE;>. Please contact support to upgrade your account",
    "uploadexceedssizeleft": "Sorry, can not continue upload. Total storage left is <&LEFTSPACE;>. You are uploading <&CURUPLOADSPACE;>",
    "foldertreeready": "Folder tree is ready to view",
    "selecttreefolder": "Please select a folder",
    "foldernofiles": "<FOLDER> does not contain any files",
    "foldereebuildindex": "Rebuilding will cause complete and non-reversible renumbering of all the contents of all the folders, would you like to proceed?",
    "sharedfilesfoldersdeleted": "Shared content is no longer available.",
    "folderdownloadnotcomplete": "Could not complete the download  the folder <&FOLDERNAME;>. Please check your internet",
    "notifsaved": "Notification saved successfully",
    "folderuploadnotallow": (restrictfoldername: string) => `Files/Folders cannot be uploaded. Unsupported special characters found in one or more folders:(${restrictfoldername}) Please change the folder name and try again`,
    "foldernotpresentorpermissionnotthere": "The folder may no longer exist or you do not have permission.",
    "filenotpresentorpermissionnotthere": "The file may no longer exist or you do not have permission.",
    "foldernotpresentcorporate": "To access this folder, switch to appropriate project from All Projects screen",
    "filenotpresentcorporate": "To access this file, switch to appropriate project from All Projects screen",
    "sharelinkgenerated": "<LINKTYPE> is generated and copied to clipboard. Now you can paste the link anywhere.",
    "folderuploading": "Uploading folders.. please wait..",
    "folderuploaded": "Folders uploaded",
    "selectregion": "Please select aws s3 region",
    "s3accesskeyenter": "Please enter AWS S3 access key",
    "s3secretkeyenter": "Please enter AWS S3 secret key",
    "s3identitypoolenter": "Please enter AWS S3 Identity Pool Id",
    "fileNotFound": "File Not Found",
    "errorfetchfiles": "Error fetching files",
    "storageaccountnameenter": "Please enter Azure file storage account name",
    "storageaccountkeyenter": "Please enter Azure file storage account key",
    "storageproviderdone": "Storage provider setup completed successfully. Redirecting to your drive page.",
    "createfolderdonevisibletoalladmins": "Folder created successfully. The folder is visible to all other Admins.",
    "createfolderdonenotvisibletoothers": "Folder created successfully. Please give the appropriate permissions to make the Folder visible to others.",
    "filesuploadedprocessvisibletoalladmins": "Files uploaded. Processing the update... Folder is visible to all other Admins.",
    "filesuploadedprocessnotvisibletoothers": "Files uploaded. Processing the update... Please give the appropriate permissions to make the Folder visible to others.",
    "morethanallowedfolderdownload": "The download started. You will receive an email with a link after download is completed. ",
    "couldnotopenexternallinkfile": "This file type is not supported to be viewed in the system. Please go to the folder path <&FOLDERPATH;> and download the file, if allowed and view it locally.",
    "fileuploadnotinit": "Error reading source folder. Please try again. In case of network folder please try to copy locally and try again.",
    "fileuploadmaxsize": "Uploading of file of size greater than 5GB is not supported",
    "selectaccountsignout": "Signed out successfully from account selected.",
    "fileuploadingfail": "Can not upload the documents. Please try again.",
    "folderuploaddomexception": "Files/Folders cannot be uploaded. Because the file path is more than 256 characters. Try shortening the path or Files/Folder name.",
    "folderalraedyindownloadlist": "The folder has been already in download list.",
    "profileimagesizeexceed": "You are uploading more than 100Kb of image sizes. So please reduce your image size and upload again.",
    "zeronotif": "No notification found.",
    "filepermissionsaved": "File permissions saved successfully.",
    "admselectonedocumentcount": "You can select <COUNT> files at a time to set <ACTION>.",
    "admstorageclasschange": "Your request has been accepted <ACTION>.",
    "admdrmapplied": "DRM settings have been modified.",
    "admdrmexpirydate": "Please enter expiry date.",
    "admdrmnorecordstoapply": "No Files are there to apply DRM.",
    "admdrmexeinstall_zip": "Please unzip the DCirrusDRM.zip file downloaded to a folder on your system. Please open the application DCirrusDRM.exe from the extracted folder.",
    "admdrmexeinstall_installer": "Installer downloaded successfully. Please double click the Installer to start installation of DCirrusDRM Application.",
    "nopermission": "None of the users have permission on the folder.",
    "filecontainhash": "The files could not be uploaded because this file contains #",
    "newqrgenerated": "New Qr Code generated for authenticator. Please scan this QR Code into your Authenticator app.",
    "usernotactive": "User/Device blocked. Please contact your administrator.",
    "dcirrusaiinfomsg": " DCirrus AI engine is preparing your dataroom for file content search.",
    "searchsyncstarted": "DCirrus AI will sync the updated file in few minutes.",
    "dcirrusairequestsent": "DCirrus AI enable request has been sent successfully.",
    "dcirrusairequestsentcorpadmin": "DCirrus AI Search is not enabled. To enable please contact your project administrator.",
    "wrongpdfpassword": "Wrong pdf password.",
    "wrongpdfpasswordexceed": "You have exceeded maximum no of attempts for entering Pdf Password. Redirecting to login page.",
    "wrongcaptcha": "Wrong captcha.",
    "enterpdfpass": "Please enter pdf password.",
    "tagexists": "Tag entered exists.",
    "documentarchived": "Can not open Document. Document is archived.",
    "admin_enterLoginEmail": "Please enter Email-ID",
    "admin_enterLoginCorrectEmail": "Please enter valid Email-ID",
    "admin_enterLoginPassword": "Please enter Password",
    "admin_enterLoginClientId": "Please enter Client-ID",
    "admin_invalidCredetials": "Invalid Credentials",
    "admin_devicesentforapproval": "Your device has been sent for approval. Contact your admin",
    "admin_devicerejected": "Your device has been rejected.Please contact admin",
    "admin_deviceblocked": "Your device has been blocked.Please contact admin",
    "admin_fetchingpendingdevices": "Please wait while fetching already pending device list",
    "admin_fetchingapproveddevices": "Please wait while fetching already approved device list",
    "admin_fetchingblockeddevices": "Please wait while fetching blocked device list",
    "admin_suretoapprove": "Do you want to approve the device?",
    "admin_suretounblock": "Do you want to unblock the device?",
    "admin_suretoreject": "Do you want to reject the device?",
    "admin_enterSearchQuery": "Please enter Search Query.",
    "admin_authenticationMsg": "Please wait while authenticating.",
    "admin_suretoblock": "Do you want to block the device?",
    "admin_logoutMsg": "Do you wish to logout?",
    "admin_loggingout": "Please wait while logging you out",
    "admin_approveMsg": "Please wait while approving the device",
    "admin_unblockMsg": "Please wait while unblocking the device",
    "admin_blockMsg": "Please wait while blocking the device",
    "admin_approveSuccessMsg": "The device approved successfully",
    "admin_unblockSuccessMsg": "The device unblocked successfully",
    "admin_deleteLawfirmSuccessMsg": "The lawfirm deleted successfully",
    "admin_deleteDomainSuccessMsg": "The domain deleted successfully",
    "admin_blockSuccessMsg": "The device blocked successfully",
    "admin_couldnotfindserver": "Sorry, could not find the server. please try again",
    "admin_deviceidnotfound": "You are attempting to access our system from a non approved device. Please contact your Admin",
    "admin_deviceidnotfoundexe": "Sorry, could not find required values. Please open exe and access ADM from the link",
    "admin_waithilecheckingcredentials": "Please wait while checking credentials",
    "admin_nodatafound": "No data found",
    "admin_clientListLoading": "Please wait while fetching client list data",
    "admin_sureToDisable": "Do you want  to disable client?",
    "admin_disableClientMsg": "Please wait while disable the client",
    "admin_diableClientSuccessMsg": "The client has disabled successfully",
    "admin_sureToEnable": "Do you want  to enable client?",
    "admin_enableClientMsg": "Please wait while enable the client",
    "admin_enableClientSuccessMsg": "The client has enabled successfully",
    "admin_userListLoading": "Please wait while fetching user list data",
    "admin_enterClientName": "Please enter Client Name.",
    "admin_enterClientEmail": "Please enter Client Email-ID",
    "admin_enterClientPhno": "Please enter Contact Number",
    "admin_enterClientFax": "Please enter Fax Details",
    "admin_enterClientId": "Please enter Customer-ID",
    "admin_enterClientRegionId": "Please enter Region Id",
    "admin_enterClientAddress": "Please enter Address",
    "admin_enterStartDate": "Please enter Start Date",
    "admin_enterEndDate": "Please enter End Date",
    "admin_failedToAddClient": "Failed To Add Client",
    "admin_clientAddingLoading": "Please wait while client adding data",
    "admin_clientDataLoading": "Please wait while loading client details",
    "admin_clientUpdatingLoading": "Please wait while client updating data",
    "admin_getMethod": "GET",
    "admin_putMethod": "POST",
    "admin_postMethod": "POST",
    "admin_clientTokenListLoading": "Please wait while fetching token list data",
    "admin_enableClientToken": "Please wait while enable the client Tokens",
    "admin_disableClientToken": "Please wait while disable the client Tokens",
    "admin_sureToResetUserPwd": "Do you want  to reset password for user?",
    "admin_resetPwdUserMsg": "Please wait while resetting password",
    "admin_resetPwdUserSuccessMsg": "The reset password done successfully",
    "admin_sureToDisableUser": "Do you want  to disable user?",
    "admin_disableUserMsg": "Do you want to disable the user?",
    "admin_disableUserSuccessMsg": "The user has disabled successfully",
    "admin_sureToEnableUser": "Do you want  to enable user?",
    "admin_enableUserMsg": "Do you want to enable the user",
    "admin_enableUserSuccessMsg": "The user has enabled successfully",
    "admin_enterUserName": "Please enter username",
    "admin_enterUserEmail": "Please enter Email-ID",
    "admin_enterCorrectUserEmail": "Please Enter valid Email-ID",
    "admin_enterImapAddress": "Please enter Imap Address",
    "admin_enterImapPort": "Please enter Imap Port",
    "admin_enterImapUser": "Please enter Imap user",
    "admin_enterSmtpAddress": "Please enter Smtp address",
    "admin_enterSmtpPort": "Please enter Smtp Port",
    "admin_enterSmtpUser": "Please enter Smtp user",
    "admin_saveUserInfoMsg": "Please wait while saving user information",
    "admin_saveUserSuccessMsg": "User details saved successfully",
    "admin_sortingMsg": "Please wait while sorting",
    "admin_userEditLoading": "Please wait while fetching user data",
    "admin_suretoPostData": "Do you want  to post comment?",
    "admin_enterCommentDataApprove": "Please enter the reason for approving the device",
    "admin_enterCommentDataUnblock": "Please enter the reason for unblocking the device",
    "admin_enterCommentDataBlock": "Please enter the reason for blocking the device",
    "admin_postCommentMsg": "The comment posted successfully",
    "admin_postComentMsg": "Please wait while posting comment data",
    "admin_clientaddTokenLoading": "Please wait while adding token",
    "admin_clientupdateTokenLoading": "Please wait while updating token",
    "admin_disableAllDeviceMsg": "Do you want  to disable all devices?",
    "admin_disableAllDevicesSuccess": "All devices are disabled successfully",
    "admin_disableAllDevicesMsg": "Please wait while disabling all devices",
    "admin_adminInformation": "information",
    "admin_adminError": "error",
    "admin_pleaseEnterTokenId": "Please enter Device Name",
    "admin_pleaseEnterTokenExpirationDate": "Please enter token expiration date",
    "admin_pleaseEnterTokenUserEmail": "Please enter Email-ID",
    "admin_pleaseEnterTokenUserName": "Please enter Username",
    "admin_enterPhoneNo": "Please enter valid phone number",
    "admin_enterFaxNo": "Please enter valid fax number",
    "admin_startDateMsg": "Start date should not be greater than end date",
    "admin_endDateMsg": "End Date should be greater than equal to Start Date",
    "admin_blockeddevicebyadmin": "Admin has blocked your device. Please contact your administrator",
    "admin_apiissue": "There is some problem with the service. Please try again",
    "admin_sureToDelete": "Do you want  to delete the device?",
    "admin_deleteDeviceMsg": "Please wait while deleting the device",
    "admin_deleteDeviceSuccessMsg": "The device deleted successfully",
    "admin_fetchUserDevicesData": "Please wait while fetching devices",
    "admin_checkAcmsDevices": "Please check ACMS",
    "admin_unCheckAcmsDevices": "Please Unchecked the ACMS",
    "admin_oldPasswordMissingMsg": "Please enter old password",
    "admin_newPasswordMissingMsg": "Please enter new password",
    "admin_confirmNewPasswordMissingMsg": "Please enter confirm password",
    "admin_mismatchNewConfirmMsg": "New Password and confirm password is not same",
    "admin_importDateMissingMsg": "Please select import date",
    "admin_changePwdDone": "Password updated successfully",
    "admin_passwordChangeMsg": "Please wait while changing password",
    "admin_responseMessageEmpty": "There is some problem with the service. Please try again",
    "admin_loadingBackToHome": "Back to home...",
    "admin_selectPrimaryEmailId": "Please select primary email id",
    "admin_enterLawFirmName": "Please enter law firm",
    "admin_savingDeviceIP": "Please wait while saving ip",
    "admin_deleteLawFirm": "Please wait while deleting the lawfirm details",
    "admin_userLastName": "Please enter last name",
    "admin_userLoginId": "Please enter login id",
    "admin_userPassword": "Please enter password",
    "admin_domainListLoading": "Please wait while loading domain list",
    "admin_deviceRuleIpErrorMsg": "IP Address should not be greater than 255",
    "admin_enterValidIpAddress": "Please enter valid IP Address",
    "admin_disableDomainMsg": "Please wait while disabling the domain",
    "admin_enableDomainMsg": "Please wait while enabling the domain",
    "admin_enterDomainName": "Please enter domain name",
    "admin_domainsAddingLoading": "Please wait while adding domain details",
    "admin_DomainDataLoading": "Please wait while loading domain data",
    "admin_domainsUpdatingLoading": "Please wait while updating domain details",
    "admin_deleteDomainMsg": "Please wait while deleting domain details",
    "admin_getBasicUserDetails": "Please wait while fetching basic user details",
    "admin_enterResetPassword": "Please enter reset password",
    "admin_enterResetLengthPassword": "Please enter atleast 6 characters",
    "admin_enterPasswordRule": "Password atleast should contain atleast one uppercase letter, one digit and one special character e.g. @$%&^!",
    "admin_resetPasswordMsg": "The reset password done successfully",
    "admin_enterServerUrl": "Please enter server url",
    "admin_enterServiceServerUrl": "Please enter service server url",
    "admin_enterValidServerUrl": "Please enter valid service url",
    "admin_invalidNumber": "Please enter valid number",
    "admin_enterEmailId": "Please enter email Id",
    "admin_enterValidDomain": "Please enter valid domain",
    "admin_enterDomainExachnageUrl": "Please enter domain server url",
    "admin_enterDomainExachangePort": "Please enter domain port number",
    "admin_allowAtleastOneLetter": "Please enter atleast one letter in <FIELDNAME>",
    "admin_enterTrashDurationVal": "Please enter trash duration between 30 to 180",
    "admin_enterSpamDurationVal": "Please enter spam duration between 30 to 180",
    "admin_enterIdexedEmailDurationVal": "Please enter indexed email duration between 30 ad 180",
    "admin_enterTrashDuration": "Please enter trash duration",
    "admin_enterSpamDuration": "Please enter spam duration",
    "admin_enterIndexedEmailDuration": "Please enter indexed email older duration",
    "admin_enterValidLoginId": "Please enter valid login id.Login id allow only Alphabet and Numeric",
    "admin_duplicateEmailID": "Email id already exists",
    "admin_enterConfirmPassword": "Please enter confirm password",
    "admin_mismatchConfirmPassword": "New password and confirm password are not same",
    "admin_enterCommentsToDeleteDevice": "Please enter the comments to delete the device",
    "admin_lawfirmSuccessMsg": "Lawfirm added successfully",
    "admin_lawfirmUpdateSuccessMsg": "Lawfirm details are updated successfully",
    "admin_enterCommentsMSg": "Please enter the comments",
    "admin_successResetPwd": "Reset password done successfully",
    "admin_successUnlockUser": "Unlock user done successfully",
    "admin_domainEnableSuccessMsg": "The user enabled successfully",
    "admin_domainDisableSuccessMsg": "The user disabled successfully",
    "admin_userEmaildDeleteFromMailBox": "The email id deleted successfully from the mailbox.",
    "admin_enterAprovalReason": "Please enter the reason for approval request",
    "admin_selectLawfirmId": "Please select lawfirm Id from the list",
    "lawfirmadedsuccessfully": "Law firm was added successfully",
    "lawfirmupdatedsuccessfully": "Law firm was updated successfully",
    "domain_added": "Domain added successfully",
    "domain_updated": "Domain updated successfully",
    "suretounlockuser": "Do you want  to unlock user?",
    "suretoDisableDomain": "Do you want  to disable domain?",
    "suretoEnableDomain": "Do you want  to enable domain?",
    "suretoDeleteDomain": "Do you want  to delete domain?",
    "sureToDeleteLawFirm": "Do you want  to delete law firm.",
    "userDetailsUpdated": "The user details are updated successfully",
    "userDetailsAdded": "The user details are added successfully",
    "waitWhileCreatingUser": "Please wait while creating user",
    "waitWhileUpdatingUser": "Please wait while updating user details",
    "waitWhileCreatingDomain": "Please wait while creating domain",
    "waitWhileUpdatingDomain": "Please wait while updating domain details",
    "aitWhileCreatingLawFirm": "Please wait while creating lawfirm",
    "waitWhileUpdatingLawFirm": "Please wait while updating lawfirm details",
    "adminAddedSuccessFully": " Approval Request Sent Successfully",
    "adminQuerySubmitSuccessfully": "Query Submit Successfully",
    "updateAdminImageSuccess": "User Image Updated Successfully",
    "userImageSize": "User Image size can not be greater than 200 KB",
    "adminQueryNotSuccessfull": "Enter Valid Query.Please try again",
    "adminRequestfailed": "Your request for sqlviewer is pending with admin. Please check with admin and try again",
    "adminHourMiniteError": "Please Enter Hour or Minute",
    "adminEnterSearchField": "Please Enter Atleast One Field.",
    "adminSearchResultNotFound": "Search Result Not Found",
    "adminEnterMandatoryFields": "Please Enter Mandatory Fields",
    "adminAutoArpprovedSuccessful": "Auto approved successfully upadated",
    "adminAutoArpprovedExists": "IP Address exists",
    "adminEmailNotDelete": "There should be at least one Email",
    "admin_sureEMailAccountToDelete": "This will remove all mails for this email account.<br/>Are you sure to delete the email account?",
    "admin_sureProxyAccountToDelete": "Are you sure to delete the proxy account?",
    "admin_passwordformat": 'Password between 8 and 20 characters. Password should contain at least one uppercase alphabet, one lowercase alphabet, one digit, and one special character from ~!@$%^&()_-#?.*{}|\':\";=+`',
    "admin_userblocked": "User blocked successfully",
    "admin_userunblocked": "User unblocked successfully",
    "admin_newusercreated": "New user created successfully.",
    "admin_selectadminuser": "Please select user type Admin or User",
    "admin_selectsynctype": "Please select user sync type personal or data room",
    "admin_selectmoduletype": "Please select user module",
    "admin_corporatedetailssaved": "Settings saved successfully.",
    "admin_norec": "No records found",
    "notpaiduser": "Sorry, you are do not have permission to perform this action. Please contact us at support@dcirrus.com",
    "usercreationexceed": "Sorry, you can add maximum of 20 users to your project. Please contact us at support@dcirrus.com for customized plan",
    "userlimitexceed": "Users limit exceeded",
    "resendactivationmailsuccess": "Successfully resend the activation mail",
    "emailidtaken": "A user already exist with this email id. Please use another email id",
    "admin_pendingapprovalexists": "User exists in pending approvals.",
    "admin_pendingapprovalcreated": "User details saved successfully, Please check pending approval.",
    "admin_userdeleted": "User deleted successfully",
    "admin_entercorpexpir": "Enter corporate id and days to expire",
    "admin_entercorpstorage": "Enter corporate id and total storage",
    "admin_entercorpusers": "Enter corporate id and total users",
    "admin_requestprocessed": "Request processed Successfully",
    "admin_requestallocateuserexeeds": "Maximum number of users limit reached. To increase the number of users, please send a request to contact@dcirrus.com with your project id",
    "admin_entertwofactor": "Please enter phone number as 2 factor authentication is selected.",
    "admin_enterdataroomclosedate": "Please enter data room closure date.",
    "admin_dataroomclosestartpopup": "Are you sure to proceed with Dataroom closure procedure?",
    "admin_dataroomclosenow": "Are you sure you want to close the dataroom now?\\nAccess to data room will be terminated for all user and administrative accounts (including yours) with immediate effect.",
    "admin_dataroomclosereason": "Please enter data room closure reason",
    "txt_dataroom_close_flashdrive_no": "Please enter no of flash drives required",
    "txt_dataroom_close_flashdrive_rec_name": "Please engter flash drive receipent name",
    "txt_dataroom_close_flashdrive_rec_email": "Please engter flash drive receipent email",
    "txt_dataroom_close_flashdrive_rec_phone": "Please engter flash drive receipent phone",
    "txt_dataroom_close_flashdrive_rec_address": "Please engter flash drive receipent address",
    "admin_dataroomclosecancel": "Are you sure to cancel the dataroom closure request?",
    "admin_dis_msg": "Please enter disclaimer message",
    "admin_dis_msg_nochange": "No change in the disclaimer message",
    "admin_dis_msg_save": "Disclaimer message saved successfully",
    "admin_dis_title_check": "Please enter disclaimer title",
    "admin_user_add": "Request has been processed. Please check the user creation report that has been downloaded in your system.",
    "admin_user_notadding": "Please check your internet and make sure you are uploading correct template format.",
    "logoimagesizeexceed": "You are uploading more than <&SIZE;> of image size. So please reduce your image size and upload again.",
    "logopngonly": "Only png image is accepted.",
    "admin_enterComment": "Please enter comment",
    "device_startDateMsg": "Enter start date greater than equals to today",
    "device_endDateMsg": "Enter end date greater than equals to start date",
    "enter_project_cost": "Enter project cost",
    "enter_project_threshhold": "Enter billable amount threshold amount",
    "enter_project_target_email": "Enter target email ids",
    "max_5_email_ids": "Maximum 5 email ids allowed",
    "timefox_project_cost_valid": "Project cost must be greater than threshold amount",
    "timefox_project_settings_unchanged": "No changes made",
    "permissionmangergroupdoesnothaveperm": "The highlighted groups are not permitted to view the current folder. Before applying file permissions, please apply view permission for the current folder.",
    "permissionmangergroupdoesnothaveperm1": "Manager group of highlighted group does not have folder permission requested. Please check permissions properly and update again.",
    "serverrestart":"Server is not reachable. Please check your internet connection and try again later",
    "no_record_found": "No record found.",
    "selectFolder":"Please select the folder",
    "dcirrus_disclaimer_title": "PERSONAL DATA PROTECTION",
    "dcirrus_disclaimer_message": "As operators of this system, we take the protection of your personal information (name, email address, phone) very seriously. We treat your personal information as confidential and handle it in accordance with Indian data protection regulations. Your personal data is being used only for the specific purpose of accessing our system. Such information shall not be disclosed to third parties without your express consent. Feel free to contact us at contact@dcirrus.com to know more about how we protect your personal information.",
    "invalid_password": "You have entered incorrect password. Please enter valid password",
    "started_indexing":(multiple: string | number) => `Indexing is in progress for selected document${multiple}. Please refersh the list after few minutes to check the status of indexing.`,
    "login_with_respective_corporate": "Please login with respective project.",
    "login_with_respective_user": "Please login with respective user.",
    "invalid_link_poll_voter": "Download link is expired, please export poll/voter details again",
    "voter_waiting_msg":(val: string | number) => `You are ${val}th in the queue, please wait, it will not take more than 15 min.`,
    "invalid_start_ip":"Invalid start IP.",
    "invalid_end_ip":"Invalid end IP.",
    "ip_added":"IP range added successfully.",
    "ip_deleted":"IP range deleted successfully.",
    "ip_mismatch":"Mismatch between start ip and end ip.",
    "agendafilesmax": "You can attach maximum of 5 files.",
    "agendafilesmaxsize": "File size can not exceed 5 MB.",
    "verifyfilesformat": "File size can not exceed 5 MB",
    "userdoesnotexist":"User does not exist",
    "replypostedsuccess":"Reply posted successfully.",
    "threadpostedsuccess":"Thread posted successfully.",
    "replydeletesuccess":"Reply deleted successfully.",
    "threadclosedsuccess":"Thread closed successfully.",
    "threadreopensuccess":"Thread reopened successfully.",
    "threadusersuccess":"User added successfully.",
    "threadtagsuccess":"Tag added successfully.",
    "threaduserremovesuccess":"User removed successfully.",
    "threadtagremovesuccess":"Tag removed successfully.",
    "emailincorrectchoice":"Only allowed emails from the given options.",
    "emailalreadyadded":"Email is already added.",
    "emailnotvalid":"Email is not valid.",
    "nopermissiondeleteemail":"You do not have permission to remove this email.",
    "atleastoneemailrequired":"Atleast one email address required.",
    "taskupdatesuccess":"Task updated successfully.",
    "taskaddsuccess":"Task added successfully.",
    "taskdeletesuccess":"Task deleted successfully.",
    "taskdownloadtemplatesuccess":"Template downloaded successfully.",
    "taskexportworkflowsuccess":"File exported successfully.",
    "taskimporttemplatesuccess":"File imported successfully.",
    "commentaddedsuccess": "Comment added successfully.",
    "commentdeletedsuccess": "Comment deleted successfully.",
    "workflowaddsuccess":"Workflow added successfully.",
    "workflowupdatesuccess":"Workflow updated successfully.",
    "workflowdeletesuccess":"Workflow deleted successfully.",
    "workflowuniquegrouperror":"Groups attached to stages should be unique.",
    "groupdeletesuccess":"Group deleted successfully.",
    "groupaddsuccess":"Group added successfully.",
    "groupupdatesuccess":"Group updated successfully.",
    "useraddsuccess":"Users updated successfully.",
    "maximumlimitreached":"This field must not exceed 100 characters",
    "reminddateexceed":"Remind date must not exceed due date.",
    "usergrouprestrictiondelete":"Group cannot be deleted because it is already associated with task",
    "taskimportreportmessage": "File uploaded and processed successfully. Please check report for more details.",
    "foldermoveerror":"Folder move could not be completed. Please try again later.",
    "destinationFolderError": "The destination folder is a subfolder of the source folder, which is not allowed.",
    "uploadfileonrootlevelerror": "For uploading files. Please select folder.",

    "usergroupselectfulladminerror":"A user can only be part of multiple groups of type 'User'.  Please change your selection and try again.",
    "usergroupselectadminerror":"If a admin group is selected, user group cannot be selected.",
    "usergroupselectmultipleadminerror":"Multiple admin groups can not be selected.",
    "usergroupselectoneerror":"Please select atleast one group.",
    "alreadypartofgroup":(groupname: string) => `This user is already part of ${groupname} cannot assign.`,
    "unabletoopenredact":"Unable to open the file for redaction.Please check if the file is encrypted.",
    "enddateimpropererror":"End Date must be a future date.",
    "scheduleblockuserremoved":(firstName: string,lastName:string) => `Scheduled block on User ${firstName} ${lastName} is removed`,
    "scheduleblockgroupremoved":(groupName: string) => `Scheduled block on Group ${groupName} is removed`,
    "scheduletobeblockedonuser":(firstName: string,lastName:string,formattedEndDate:string | null) => `User ${firstName} ${lastName} is scheduled to be blocked on ${formattedEndDate}.`,
    "scheduletobeblockedongroup":(groupName: string,formattedEndDate:string | null) => `Group ${groupName} is scheduled to be blocked on ${formattedEndDate}.`,
    "scheduleddateinvalid":"Scheduled date is missing or invalid.",
    "invaliddateformat":"Invalid date format.",
    "blockuserfailed":"Failed to block user. Please try again.",
    "admin_groupblocked":(groupName:string) => `Group ${groupName} blocked successfully.`,
    "admin_groupunblocked":(groupName:string) => `Group ${groupName} unblocked successfully`,
    "uploaddummypreviewmessage":"This is the current folder, where the list of files to be uploaded will be displayed.",
}