// screenshot-blocker.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenshotBlockerService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initializeBlocker() {
    this.renderer.listen('document', 'keydown', (event: KeyboardEvent) => {
      if (
        event.key === 'PrintScreen' || // Detect Print Screen key
        (event.ctrlKey && event.key === 's') // Detect Ctrl+S
      ) {
        // Clear clipboard to prevent pasting
        navigator.clipboard.writeText('');
        event.preventDefault(); // Prevent default action if needed
      }
    });

    this.renderer.listen('window', 'blur', () => {
      document.body.style.filter = 'blur(10px)'; // Apply blur to the entire page
    });
    
    this.renderer.listen('window', 'focus', () => {
      document.body.style.filter = 'none'; // Remove the blur
    });
  }
}
