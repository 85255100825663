import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, Router } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { userGuard } from './guards/user.guard';
import { CONSTANT } from 'src/app/constants/constant';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  //{ path: 'corp-list', loadChildren: () => import('./modules/corp-list/corp-list.module').then(m => m.CorpListModule) },
  { path: 'switch', loadChildren: () => import('./modules/standalone/switch/switch.routes')},
  { path: 'viewer-direct', loadChildren: () => import('./modules/viewer-direct/viewer-direct.module').then(m => m.ViewerDirectModule) },
  { path: 'verify', loadChildren: () => import('./modules/verify/verify.module').then(m => m.VerifyModule) },
  { path: 'assistant', loadChildren: () => import('./modules/document-assistant/document-assistant.module').then(m => m.DocumentAssistantModule) },
  { path: 'auth', canActivate: [authGuard], loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'voter/:corporateId/:pollId', loadComponent: () => import('./public-modules/voter/voter.component').then(m => m.VoterComponent) },
  { path: 'public/outbound/:corporateId/:contentId/:authKey', loadComponent: () => import('./public-modules/shared/shared.component').then(m => m.SharedComponent) },
  { path: 'public/outbound/:corporateId/:contentId/:folderId/:authKey', loadComponent: () => import('./public-modules/shared/shared.component').then(m => m.SharedComponent) },
  { path: 'public/outbound/:corporateId/:contentId/:folderId/:authKey/:t', loadComponent: () => import('./public-modules/shared/shared.component').then(m => m.SharedComponent) },
  { path: 'public/inbound/:corporateId/:contentId/:authKey', loadComponent: () => import('./public-modules/inbound/inbound.component').then(m => m.InboundComponent) },
  { path: ':role', canActivate: [userGuard], loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule) },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private router: Router) {
    const lc = window.location.href; 
    if(CONSTANT.getToken()!=null){
      if(lc.indexOf("/web/auth/reset-password?")>0 || lc.indexOf("/web/auth/closure?id=")>0){
        CONSTANT.logout();
      }
    }
  }
}
